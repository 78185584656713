import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getUserSusbcriptionInfo } from "store/actions"

const AlertBar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [close, setClose] = useState(false)

  const { subscribe, loading } = useSelector(state => state.userSubscription)

  useEffect(() => {
    dispatch(getUserSusbcriptionInfo())
  }, [dispatch])

  useEffect(() => {
    setClose(false)
    if (location.pathname === "/subscription-plan") {
      setClose(true)
    }
  }, [location.pathname])

  const showAlertBar =
    !loading &&
    !close &&
    subscribe &&
    subscribe.is_trial &&
    !subscribe.subscribed &&
    subscribe.trial_days_remaining > 0

  return (
    <>
      {showAlertBar && (
        <div id="alert-bar">
          <div id="hellobar-bar" className="regular closable">
            <div className="hb-content-wrapper">
              <div className="hb-text-wrapper">
                <div className="hb-headline-text">
                  <p>
                    <span>
                      {`Your trial will expire in ${subscribe?.trial_days_remaining} days`}
                    </span>
                  </p>
                </div>
              </div>
              <Link
                to="/subscription-plan"
                className="hb-cta hb-cta-button"
                onClick={() => setClose(true)}
              >
                <div className="hb-text-holder">
                  <p>Subscribe Now</p>
                </div>
              </Link>
            </div>
           {/*  <div className="hb-close-wrapper">
              <button className="icon-close" onClick={() => setClose(true)}>
                ✖
              </button>
            </div> */}
          </div>
        </div>
      )}
    </>
  )
}

export default AlertBar
